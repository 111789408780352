import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import Header from '../page/Header';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

const getLocationClass = locationTitle => locationTitle.toLowerCase().replace(/[ ,]+/g, '-');

export const ShoppingList = ({ baseURL, contentTitle, ingredients, pageTitle, recipeId }) => (
  <div className="gel-pica">
    <MetaProperties
      title={`${contentTitle} shopping list`}
      canonical={`${baseURL}/food/recipes/${recipeId}`}
    />
    <Helmet>
      <meta name="robots" content="NOINDEX, FOLLOW" />
    </Helmet>

    <Header pageTitle={pageTitle} />
    <div className="food-grid">
      <div className="gel-wrap food-content-wrapper">
        <div className="shopping-list-body">
          <div className="gel-wrap">
            <div className="shopping-list">
              <h1 className="gel-trafalgar content-title__text">{contentTitle}</h1>

              {ingredients.map(location => {
                const locationClass = getLocationClass(location.locationTitle);

                return (
                  <div
                    key={locationClass}
                    className={`shopping-list__group shopping-list__group--${locationClass}`}
                  >
                    <h2 className="shopping-list__location-heading gel-pica-bold">
                      {location.locationTitle}
                    </h2>

                    {location.ingredients.map((ingredient, i) => {
                      const ingredientId = `${locationClass}-ingredient-${i}`;
                      return (
                        <p key={ingredientId} className="shopping-list__ingredient">
                          <input
                            type="checkbox"
                            className="shopping-list__checkbox"
                            id={ingredientId}
                          />
                          <label
                            className="shopping-list__label gel-pica"
                            htmlFor={ingredientId}
                            dangerouslySetInnerHTML={{ __html: ingredient }}
                          />
                        </p>
                      );
                    })}
                  </div>
                );
              })}

              <a className="shopping-list__back-link gel-pica" href={`/food/recipes/${recipeId}`}>
                Back to recipe
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ShoppingList.propTypes = {
  baseURL: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      locationTitle: PropTypes.string.isRequired,
      ingredients: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ).isRequired,
  pageTitle: PropTypes.string.isRequired,
  recipeId: PropTypes.string.isRequired,
};

export default connect(state => ({
  baseURL: state.pageReducer.baseURL,
  contentTitle: state.shoppingListReducer.contentTitle,
  ingredients: state.shoppingListReducer.ingredients,
  pageTitle: state.pageReducer.pageTitle,
  recipeId: state.shoppingListReducer.recipeId,
}))(ShoppingList);
